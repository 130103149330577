<template>
    <v-row>
        <v-dialog
        v-model="show"
        persistent
        max-width="500"
        v-if="resolutionScreen >= 500"
        >
            <v-card>
                <v-card-text class="pa-0">
                <v-list class=" pa-0">
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title style="font-size:large;">
                            ID Card Number
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon size="35" dense dark :color="color.theme">mdi-id-card</v-icon>
                    </v-list-item-action>
                    </v-list-item>
                </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                <v-layout row wrap align-center justify-center>
                    <v-flex xs11 lg11 ma-5>
                    <v-card class="elevation-0">
                        <v-layout row wrap justify-center>
                        <v-flex lg10 xs10 class="text-center">
                            <v-form ref="form">
                            <v-text-field
                                :disabled="isloading || isloadingprogress"
                                persistent-hint
                                multiple
                                outlined
                                :hint="descresption_status"
                                :label="this.$t('default') === 'th' ? 'เลขบัตรประชาชน': 'ID Card Number'"
                                dense
                                :oninput="regexTaxid"
                                v-model.trim="idcard_number"
                                autocomplete="off"
                                :rules="requiredIDcard"
                                :error-messages="errormessage_idcard"
                                @blur="$v.idcard_number.$touch()"
                            >
                            </v-text-field>
                            </v-form>
                        </v-flex>
                        </v-layout>
                        <v-layout justify-center v-if="isUpdatedIdCard">
                            <v-flex lg10 xs10 class="text-center">
                                <span>
                                    อัพเดตเสร็จเรียบร้อยแล้ว
                                </span>
                                <v-icon color="green">
                                    mdi-check-circle
                                </v-icon>
                            </v-flex>
                        </v-layout>
                        <v-layout justify-center  v-if="isFinishedtax">
                            <v-flex lg10 xs10 class="text-center mt-3">
                                <span style="color: green;">
                                    เลขบัตรประชาชนนี้ไม่มีไฟล์ตกค้าง
                                </span>
                                <!-- <v-icon color="green">
                                    mdi-check-circle
                                </v-icon> -->
                            </v-flex>
                        </v-layout>
                        
                    </v-card>
                    </v-flex>
                </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-progress-circular
                    v-if="isloading && isloadingprogress === false"
                    color="teal"
                    indeterminate
                    width="2"
                    size="20"
                >

                </v-progress-circular>
                <v-layout align-center v-if="isloadingprogress">
                    <v-progress-circular 
                        width="4"
                        size="25"
                        :value="valueProgress"
                        :color="color.theme"
                    />
                    <span class="ml-3">  {{countfinished}} / {{ data_taxbox.length }} </span>
                </v-layout>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="isloading || isloadingprogress"
                    color="red"
                    outlined
                    :dark="isloading || isloadingprogress ? false : true"
                    @click="closedialogtaxbox"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                >
                <v-btn :color="color.theme" :dark="disableverifyidcard ? false : true"
                :disabled="disableverifyidcard"
                @click="verifyidcard">ยืนยัน</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="show"
        persistent
        :max-width="maxWidthOnMobile"
        content-class="rounded-lg"
        v-else
        >
            <v-card>
            <v-card-text class="pa-5">
                <v-layout class="pt-2 pb-4">
                    <v-flex d-flex justify-center class="ml-8">
                    <span :style="headerPage"> ID Card Number </span>
                    </v-flex>
                    <v-flex xs1 d-flex justify-end>
                    <v-icon
                        :color="color.theme"
                        @click="closedialogtaxbox"
                        >mdi-close</v-icon
                    >
                    </v-flex>
                </v-layout>
                <v-form ref="form">
                    <v-text-field
                    class="mt-1 textFieldClass"
                    :color="color.theme"
                    outlined
                    :disabled="isloading"
                    dense
                    :label="this.$t('default') === 'th' ? 'เลขบัตรประชาชน': 'ID Card Number'"
                    :hint="descresption_status"
                    v-model.trim="idcard_number"
                    height="35px"
                    autocomplete="off"
                    persistent-hint
                    :readonly="isUpdatedIdCard"
                    :success-messages="isUpdatedIdCard || isFinishedtax ? descresption_status_mobile : ''"
                    :oninput="regexTaxid"
                    :rules="requiredIDcard"
                    :error-messages="errormessage_idcard"
                    @blur="$v.idcard_number.$touch()"
                ></v-text-field>
                </v-form>
                <v-progress-circular
                    v-if="isloading && isloadingprogress === false"
                    color="teal"
                    indeterminate
                    width="2"
                    size="18"
                >

                </v-progress-circular>
                <v-layout align-center class="mt-3" v-if="isloadingprogress">
                    <v-progress-circular 
                        width="4"
                        size="25"
                        :value="valueProgress"
                        :color="color.theme"
                    />
                    <span class="ml-3" style="font-size: 14px;">  {{countfinished}} / {{ data_taxbox.length }} </span>
                </v-layout>
                <div class="text-center pt-5">
                    <v-btn
                    :style="btnAction"
                    class="mr-6"
                    height="40px"
                    width="45%"
                    color="red"
                    :disabled="isloading || isloadingprogress"
                    :dark="isloading || isloadingprogress ? false : true"
                    outlined
                    @click="closedialogtaxbox"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                    >
                    <v-btn
                    class="elevation-0"
                    :style="btnAction"
                    height="40px"
                    width="45%"
                    :disabled="disableverifyidcard"
                    :color="color.theme"
                    :dark="disableverifyidcard ? false : true"
                    @click="verifyidcard"
                    >ยืนยัน</v-btn
                    >
                </div>
            </v-card-text>
            </v-card>
        </v-dialog>

        <dialogupdateidcard
            :show="opendialog_update_id_card"
            :idCard="idcard_number"
            @closedialogupdate="closeupdatedialog"
        >

        </dialogupdateidcard>
        
    </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";

const dialogupdateidcard =  () => import("@/components/optional/dialog-updateidcard.vue")
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
    mixins: [validationMixin],
    validations: {
        idcard_number: { required },
    },
    components: {
        dialogupdateidcard
    },
    props: ["show"],
    data () {
        return {
            checkIsValidIdCard: true,
            idcard_number: "",
            isloading: false,
            descresption_status: "ใส่เลขบัตรประชาชน 13 หลัก",
            descresption_status_mobile: "",
            isUpdatedIdCard: false,
            regexTaxid: "this.value = this.value.replace(/[^0-9]|/g, '').substr(0,13)",
            valueProgress: 0,
            countfinished: 0,
            calculate_total: 0,
            data_taxbox: [],
            data_test: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            isFinishedtax: false,
            isloadingprogress: false,
            messageerrorreasonidcard: "",
            disableverifyidcard: false,
            opendialog_update_id_card: false,
            // requiredIdcard: [
            //     (value) => !!value ||  "จำเป็นต้องใส่เลขบัตรประชาชน",
            // ]
            requiredIDcard: [
                (value) => !!value || "โปรดระบุเลขบัตรประชาชน",
                (value) =>  (this.fn_checkidcard(value)) || this.messageerrorreasonidcard,
                // (value) =>  (this.fn_checknumberlength(value)) || "ห้ามใส่เลขบัตรประชาชนเกินจำนวน 13 หลัก",
            ],
        }
    },
    computed: {
        ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataOneAccountId"
    ]),
    resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return 220;
            case "sm":
            return 400;
            case "md":
            return 500;
            case "lg":
            return 600;
            case "xl":
            return 800;
        }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    title() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    textfield() {
      return "min-height: 1px;";
    },
    success() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 500;
      } else {
        return 346;
      }
    },
        errormessage_idcard () {
            const errors = []
            if(this.checkIsValidIdCard === false) {
                errors.push(this.messageerrorreasonidcard);
                return errors;
            }
        }
    },
    methods: {
        fn_checkidcard(value) {
            const pattern = /^[0-9]*$/;
            if(value === undefined){
                return
            }
            if (pattern.test(value) == true) {
                if(value.length <= 13){
                    this.checkIsValidReason = true;
                    this.disableverifyidcard = false
                    return true
                } else if (value.length > 13) {
                    this.checkIsValidReason =  false
                    this.disableverifyidcard = true
                    this.messageerrorreasonidcard = "ห้ามใส่เลขบัตรประชาชนเกินจำนวน 13 หลัก"
                    return false
                }
                this.checkIsValidReason = true;
                this.disableverifyidcard = false
                return true;
            } else {
                this.disableverifyidcard = true
                this.messageerrorreasonidcard = "ห้ามใส่อักษรอื่นยกเว้นตัวเลข"
                return false;
            }
        },
        closedialogtaxbox () {
            this.$emit("closedialog")
            this.idcard_number = ""
            this.$refs.form.reset();
            this.isloading = false
            this.isloadingprogress = false
            this.descresption_status = "ใส่เลขบัตรประชาชน 13 หลัก"
            setTimeout(() => {
             this.disableverifyidcard = false
             this.isFinishedtax = false
            }, 500)
            
        },
       async verifyidcard () {
            
            if(this.idcard_number.length < 13){
                this.checkIsValidIdCard = false
                this.messageerrorreasonidcard = "โปรดระบุเลขบัตรประชาชน 13 หลัก"
                setTimeout(() => {
                this.checkIsValidIdCard = true;
                this.messageerrorreasonidcard = "";
                }, 1200);
            } else {
                this.disableverifyidcard = true
                this.isloading = true
                //CALL API DIRECT
                await this.fn_load_inbox_from_update()
                


            }
        },
        closeupdatedialog (dataconsent, type) {
            if(dataconsent){
                console.log("consent update Ok")
                this.isUpdatedIdCard = true
                this.descresption_status = ""
                this.descresption_status_mobile = "อัพเดตเสร็จเรียบร้อยแล้ว"
                this.opendialog_update_id_card = false
                // this.isloadingprogress = true
                this.fn_load_inbox_from_update()
            } else {
                if(type === "ERR"){
                    this.opendialog_update_id_card = false
                    this.disableverifyidcard = false
                    this.messageerrorreasonidcard = "เลขบัตรประชาชนไม่ถูกต้อง"
                    this.checkIsValidIdCard = false
                    setTimeout(() => {
                        this.messageerrorreasonidcard = ""
                        this.checkIsValidIdCard = true
                    }, 2000)
                } else  {
                    this.opendialog_update_id_card = false
                    this.disableverifyidcard = false
                }
                
                //this.$emit("closedialog")
                // setTimeout(() => {
                // console.log("Cancel consent update ")
                // this.idcard_number = ""
                // this.$refs.form.reset();
                // this.isloading = false
                // this.disableverifyidcard =false
                // }, 500)
            }
        },

    

        async fn_load_inbox_from_update () {
            var payload = {
                id_card: this.idcard_number,
                oneid_user_id: this.dataOneAccountId,
            };
            let url = "/api/taxbox/get_file_from_id_card"
            let auth = await gbfGenerate.generateToken();
             
            await this.axios.post(
                process.env.VUE_APP_SERVICE_DOWNLOAD_API + url, 
                payload, 
                {headers: { Authorization: auth.code }})
            .then(async (response) => {
                if(response.data.status === 'OK'){
                    console.log("data response ", response)
                    this.data_taxbox = response.data.data
                    if(this.data_taxbox.length === 0){
                        this.isFinishedtax = true
                        this.descresption_status_mobile = "เลขบัตรประชาชนนี้ไม่มีไฟล์ตกค้าง" // ใช้กับ mobile
                        //ใช้กับ mobile
                        setTimeout(() => {
                            this.descresption_status_mobile = ""
                            this.isFinishedtax = false
                        }, 1800)
                        this.isloading = false
                        this.disableverifyidcard = false
                        return
                    }   
                    this.isloading = false
                    this.isloadingprogress = true
                    await this.fn_upload_tax(this.data_taxbox)
                } else {
                    if(response.data.errorCode === 'ER501'){
                        this.isloading = false
                        this.disableverifyidcard = false
                        this.messageerrorreasonidcard = "เลขบัตรประชาชนที่ระบุไม่ตรงกับเลขบัตรประชาชนของบัญชีผู้ใช้นี้"
                        this.checkIsValidIdCard = false
                        setTimeout(() => {
                            this.messageerrorreasonidcard = ""
                            this.checkIsValidIdCard = true
                        }, 3000)
                    } else if (response.data.errorCode === 'ER502') {
                        this.isloading = false 
                        this.opendialog_update_id_card = true
                    }
                }
            })
            .catch((err) => {
                console.log("error ", err)
                Toast.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาดบางอย่าง",
                });
            })
        },

        async fn_upload_tax (data_taxbox_array) {
            for(var i = 0; i < data_taxbox_array.length; i++){
                let url = data_taxbox_array[i]
                await this.axios
                .get(url)
                .then((response) => {
                    if(response.data.status === 'OK') {
                        this.countfinished++
                        if(data_taxbox_array.length >= 100){
                            this.calculate_total += (100 / data_taxbox_array.length)
                            this.valueProgress = Math.floor(this.calculate_total)
                        }else {
                            this.valueProgress += Math.floor(100/data_taxbox_array.length);
                        }

                        // this.countfinished++
                        // this.valueProgress += Math.floor(100/data_taxbox_array.length);
                    }
                })

                if(i === data_taxbox_array.length - 1){
                    this.valueProgress = 100
                    this.countfinished = this.data_taxbox.length
                    setTimeout(() => {
                        this.isUpdatedIdCard = false
                        this.idcard_number = ""
                        this.$refs.form.reset();
                        this.isloading = false
                        this.valueProgress = 0
                        this.countfinished = 0
                        this.calculate_total = 0
                        this.isloadingprogress = false
                        this.isFinishedtax = false
                        this.descresption_status = "ใส่เลขบัตรประชาชน 13 หลัก"
                        this.descresption_status_mobile = ""
                        this.$emit("closedialogupdate_inbox")
                        this.disableverifyidcard = false
                    }, 2500)
                }
            }
        },
        
    }
}
</script>
